<template>
  <div class="zee-container">
    <zee-toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="20000" @clearToast="clearToast" />
    <zee-screen-title :title="'Enter New Program Details'" :route="'/solutions/zeetv/program-launch'" />

    <div class="zee-card-background">
      <div class="zee-multi-tabs-wrapper">
        <zee-tabs title="Genre" icon="chevron-right">
          <zee-dropdown :options="GenreDropdown" :selectedOption="selectedGenre" :placeHolder="'Select'"
            @input="handleDropdownChange($event)" class="mr-3" />
        </zee-tabs>
        <zee-tabs title="Program Name" icon="pen">
          <div class="d-flex">
            <zee-input :value="this.inputValue" :active="false" @onChange="getProgramInput">
              <!-- <zee-button @onClick="handleSubmit" title="submit" /> -->
            </zee-input>
            <div class="fs-5 d-flex align-items-center" style="color: red" v-if="programAlreadyExists">
              Program name already exist, give diffrent name
            </div>
          </div>
        </zee-tabs>
        <zee-tabs title="Program Launch Date" icon="calendar">
          <div class="d-flex">
            <zee-calendar :placeHolder="'Select'" :labelText="'Date'" @getDate="getDateInput($event)" />
            <div class="ml-3 d-flex align-items-center fs-5 font-weight-bold">
              selected date: {{ this.formatedDate }}
            </div>
          </div>
        </zee-tabs>
        <zee-tabs title="Program Runtime" icon="clock">
          <div class="d-flex align-items-center fs-3 font-weight-bold">
            <zee-runtime @from="getInitialRuntime" @to="getFinalRuntime" />
            <div v-if="showRuntime" class="fs-2 font-weight-bold ml-3">
              {{ formatedRuntime }}
            </div>
          </div>
        </zee-tabs>
        <zee-tabs title="Program Frequency" icon="play">
          <zee-frequency :selectedDay="selectedDay" :customSelectedDays="customSelectedDays" @select="getSelectedDay"
            @dynamic="getDynamicDay" />
          <!-- <zee-multi-select
            @getSelectedHeader="getSelectedOptions"
            :options="FrequencyDropdown"
          /> -->
        </zee-tabs>
      </div>
      <!-- <div>{{selectedDays}}</div> -->
      <div class="zee-program-save-button">
        <zee-button style="background-color: #6db056" @onClick="handleClick" title="Save" :icon="'save'"
          :isDisable="false"></zee-button>
        <!-- <zee-button
          class="d-none"
          style="background-color: #000"
          @onClick="goNext"
          title="Next"
          :icon="'arrow-right'"
          :isDisable="false"
        ></zee-button> -->
      </div>
    </div>
    <page-loader v-if="isLoading"></page-loader>
  </div>
</template>

<script>
import {
  Tabs,
  Input,
  Button,
  Calendar,
  Dropdown,
  Runtime,
  // MultiDropdown,
  ScreenTitle,
  Frequency,
} from "../components";
import Toast from "@/components/Toast/Toast.vue";
import PageLoader from "@/widgets/PageLoader.vue";
import debounce from "lodash.debounce";

import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "ProgramDetails",
  components: {
    "zee-tabs": Tabs,
    "zee-input": Input,
    "zee-button": Button,
    "zee-calendar": Calendar,
    "zee-runtime": Runtime,
    "zee-dropdown": Dropdown,
    "page-loader": PageLoader,

    // "zee-multi-select": MultiDropdown,
    "zee-screen-title": ScreenTitle,
    "zee-frequency": Frequency,
    "zee-toast": Toast,
  },

  data() {
    return {
      // isNextDisabled:false,
      programAlreadyExists: false,
      showRuntime: false,
      inputValue: "",
      isLoading: false,
      selectedDay: "",
      formatedRuntime: "",
      isValueSubmitted: false,
      selectedGenre: {},
      selectedDate: "",
      formatedDate: "",
      selectedFrequency: "",
      GenreDropdown: [
        { text: "Fiction", id: 0 },
        { text: "Non Fiction", id: 1 },
        { text: "WTP", id: 2 },
        { text: "Events", id: 3 },
      ],
      FrequencyDropdown: [
        {
          id: 0,
          name: "Sunday",
          value: false,
          $isDisabled: false,
          key: "Sunday",
        },
        {
          id: 1,
          name: "Monday",
          value: false,
          $isDisabled: false,
          key: "Monday",
        },
        {
          id: 2,
          name: "Tuesday",
          value: false,
          $isDisabled: false,
          key: "Tuesday",
        },
        {
          id: 3,
          name: "Wednesday",
          value: false,
          $isDisabled: false,
          key: "Wednesday",
        },
        {
          id: 4,
          name: "Thursday",
          value: false,
          $isDisabled: false,
          key: "Thursday",
        },
        {
          id: 5,
          name: "Friday",
          value: false,
          $isDisabled: false,
          key: "Friday",
        },
        {
          id: 6,
          name: "Saturday",
          value: false,
          $isDisabled: false,
          key: "Saturday",
        },
      ],
      statsDropdown: [
        { text: "Elite", id: "elite" },
        { text: "Pro", id: "pro" },
        { text: "Lite Plan", id: "Lite Plan" },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      programDetails: {},
      minRuntime: "",
      maxRuntime: "",
      programFrequency: [],
      selectedDays: [],
      customSelectedDays: [],
    };
  },
  created() {
    // sessionStorage.setItem("show_add_program_screen", false)
    this.retriveData();
  },
  computed: {},
  methods: {
    getSummaryTableData(e) {
      this.isLoading = true;
      let clientId = sessionStorage.getItem("clientId");
      zeeSolutions
        .outputSummaries(clientId)
        .then((res) => {
          let data = res.filter((x) => x.programName === e);
          data.length > 0
            ? ((this.toastData = {
              show: true,
              message: "Program name already exist, please give diffrent name",
              type: "info",
            }),
              (this.programAlreadyExists = true))
            : (this.programAlreadyExists = false);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err);
        });
    },
    getSelectedDay(e) {
      this.selectedDays = [];

      this.selectedDays = [...e];
      // console.log("selected day event", e);
      // let selectedDaysInWords = []
      // selectedDaysInWords.push(this.FrequencyDropdown[this.selectedDay-1].name)
      // for(let i = 0; i<this.selectedDays; i++){
      //   selectedDaysInWords.push(this.FrequencyDropdown[this.selectedDays].name)
      // }
    },
    getDynamicDay() {
      // this.selectedDays = []

      // this.selectedDays = [...e];
      // let selectedDaysInWords = [...e]
      // for(let i = 0; i<selectedDaysInWords.length; i++){
      //   let id = ""
      //   for(let j = 0; j<7; j++){
      //     if(this.FrequencyDropdown[j].name === selectedDaysInWords[i]){
      //       id = this.FrequencyDropdown[j].id
      //       break;
      //     }
      //   }
      //   this.selectedDays = [+id];
      // }
      // console.log("dynamic selected day event", e);
    },
    runTime() {
      let runtime;
      let startTime = +localStorage.getItem("startTime");
      let endTime = +localStorage.getItem("endTime");

      if (startTime < endTime) {
        let sum = endTime - startTime;
        if (sum === 70) {
          sum = 30;
        }
        // console.log(sum);
        if (!isNaN(sum)) {
          let split1 = sum.toString().split("");
          // console.log(split1);
          if (split1.length > 2) {
            runtime = `${+split1[0]} hrs ${+split1[1] + split1[2]} mins`;
            if (split1.length > 3) {
              runtime = `${+split1[0] + split1[1]} hrs ${+split1[2] + split1[3] === 70 ? 30 : 30
                } mins`;
            }
          } else {
            runtime = `${+split1[0] + "0"} mins`;
          }
          this.formatedRuntime = runtime;
        }
        if (this.formatedRuntime === "00 mins") {
          this.formatedRuntime = "";
        }
        this.toastData = {
          show: false,
          message: "",
          type: "info",
        };
        this.showRuntime = true;

        return runtime;
      } else {
        this.showRuntime = false;
        this.toastData = {
          show: true,
          message: "Start Time should be less than End Time",
          type: "info",
        };
      }
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    getProgramInput(e) {
      this.clearToast();
      this.inputValue = e;
      const dataChange = debounce(() => this.getSummaryTableData(e), 5000);
       console.log(dataChange())
    },
    handleDropdownChange(e) {
      this.selectedGenre = e;
      sessionStorage.setItem("selectedGenre", e.text);
    },
    handleClick() {
      let data = JSON.parse(localStorage.getItem("programDetails"));
      // console.log("this is selected in words", selectedDaysInWords)
      // console.log("handle click", this.selectedDays);
      // let selectedDaysInWords = [this.FrequencyDropdown[this.selectedDay].name]
      // selectedDaysInWords.push(this.FrequencyDropdown[this.selectedDay].name)
      // console.log(selectedDaysInWords)
      data[0].programName = this.inputValue;
      data[0].programGenre = this.selectedGenre.text;
      (data[0].programLaunchDate = this.formatedDate),
        (data[0].programRuntime = {
          startTime: this.minRuntime,
          endTime: this.maxRuntime,
        });
      // (data[0].programFrequency = [...new Set(selectedDaysInWords)]);
      localStorage.setItem("programDetails", JSON.stringify(data));

      let isButtonDisabled = this.disableButton(...data);
      // isButtonDisabled = this.isNextDisabled
      // console.log(isButtonDisabled)

      !isButtonDisabled
        ? (this.toastData = {
          show: true,
          type: "edit",
          message: "Please fill valid values in all fields",
        })
        : this.$router.push(`/solutions/zeetv/competitor-Launch-targets`);
    },
    // goNext() {
    //   // console.log("in the go next")
    //   this.$router.push(`/solutions/zeetv/competitor-Launch-targets`);
    // },
    getDateInput(e) {
      this.selectedDate = e;
      this.formatedDate = this.formatDate(e);
      if (this.formatedDate === "1970-01-01") {
        this.formatedDate = "";
      }
      // console.log(this.formatedDate)
      const day = e.getDay();
      this.selectedDay = day;
      sessionStorage.setItem("formattedDate", this.formatDate(e));
      localStorage.setItem("selectedDay", this.selectedDay);
    },
    getSelectedOptions(e) {
      let temp = [];

      for (var i = 0; i < e.length; i++) {
        temp.push(this.selectedFrequency[i].key);
      }
      this.programFrequency = temp;
    },
    getInitialRuntime(e) {
      this.minRuntime = e;
      e !== "0NaN" ? this.runTime() : "";

    },
    getFinalRuntime(e) {
      // console.log("max", e);
      // console.log(e === "0NaN");
      this.maxRuntime = e;
      e !== "0NaN" ? this.runTime() : "";
    },

    retriveData() {
      let data = JSON.parse(localStorage.getItem("programDetails"));
      this.selectedGenre = { text: data[0].programGenre, id: 1 };
      this.inputValue = data[0].programName;
      this.formatedDate = data[0].programLaunchDate;
      this.selectedDay = +localStorage.getItem("selectedDay");
      let selectedDaysInWords = data[0].programFrequency;
      // console.log(selectedDaysInWords);
      let weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      for (let i = 0; i < selectedDaysInWords.length; i++) {
        this.customSelectedDays.push(weekDays.indexOf(selectedDaysInWords[i]));
      }
      // console.log("these are selected Days", this.customSelectedDays);
    },

    //utility function
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    disableButton(data) {
      if (
        data.programName !== "" &&
        data.programLaunchDate !== "" &&
        data.programGenre !== undefined &&
        data.programRuntime.startTime !== "0NaN" &&
        data.programRuntime.endTime !== "0NaN" &&
        data.programRuntime.endTime !== data.programRuntime.startTime &&
        this.programAlreadyExists === false &&
        this.showRuntime === true
        // data.programFrequency.length !== 0
      ) {
        // console.log(true)
        return true;
      } else {
        //  console.log(false)
        return false;
      }
    },
  },
};
</script>

<style>
.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  height: 100%;
}

.zee-card-background {
  padding: 3rem;
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  border-radius: 15px;
}

.zee-multi-tabs-wrapper {
  width: 100%;
  gap: 3rem;
}

.zee-program-save-button {
  display: flex;
  justify-content: flex-end;
}
</style>
